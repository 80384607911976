export default {
    config: { useRootStyles: true },
    breakpoints: ['760px', '1024px', '1280px'],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    colors: {
      text: "#333",
      background: "#fff",
      primary: "#639",
      secondary: "#ff6347",
    },
    fonts: {
      body: "Georgia, serif",
      heading: "Menlo",
      monospace: "Menlo, monospace",
    },
    fontWeights: {
      body: 400,
      heading: 700,
      bold: 700,
    },
    lineHeights: {
      body: 1.5,
      heading: 1,
    },
    fontSizes: [14, 18, 20, 24, 36, 48, 60], 
    styles: {
      root: {
        fontFamily: 'body',
        fontWeight: 'body',
        fontSize: 1,
      },
      h1: {
        variant: 'text.heading',
        fontSize: 6, 
      },
      h2 : {
        variant: 'text.heading',
        fontSize: [4,4,5],
      },
      h3: {
        variant: 'text.heading',
        fontSize: [3,4],
      },
      h4: {
        variant: 'text.heading',
        fontSize: [2,3],
      },
      p: {
        fontFamily: 'body',
        fontSize: 1,
        lineHeight: 1.3,
      },
      a: {
        default: {
          color: 'hotRed',
          fontWeight: 500,
          textDecoration: 'none',
          ':hover': {   
            textDecoration: 'underline',
            textUnderlineOffset: '2px',
            textDecorationThickness: '2px',
          },
        },
      }
    }
  }
